import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07e33759"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuSpinner = _resolveComponent("LuSpinner")!
  const _component_LuLeftMenu = _resolveComponent("LuLeftMenu")!
  const _component_PageSectionComponent = _resolveComponent("PageSectionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LuSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.leftMenuItems)
      ? (_openBlock(), _createBlock(_component_LuLeftMenu, {
          key: 1,
          menu: _ctx.leftMenuItems
        }, null, 8, ["menu"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.leftMenuItems ? 'content' : 'content-no-menu')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
        return (_openBlock(), _createBlock(_component_PageSectionComponent, {
          key: section.id,
          section: section,
          heading: _ctx.sectionTitle(section),
          content: _ctx.sectionContent(section),
          anchor: section.id.toString(),
          linkText: _ctx.sectionLinkText(section),
          routerLinkUrl: section.routerLinkUrl,
          externalLinkUrl: section.externalLinkUrl,
          showSeparator: index !== _ctx.sections.length - 1
        }, null, 8, ["section", "heading", "content", "anchor", "linkText", "routerLinkUrl", "externalLinkUrl", "showSeparator"]))
      }), 128))
    ], 2)
  ]))
}