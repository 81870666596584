import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0891e7c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "section-title page-heading" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "spacer" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "spacer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["section", { 'bottom-border': _ctx.showSeparator }])
  }, [
    _createElementVNode("a", { id: _ctx.anchor }, null, 8, _hoisted_1),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("div", {
      class: "section-content",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_3),
    (_ctx.routerLinkUrl)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          onClick: _ctx.saveLinkUrl,
          to: _ctx.routerLinkUrl
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.linkText) + " ", 1),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, { icon: "chevron-circle-right" })
            ])
          ]),
          _: 1
        }, 8, ["onClick", "to"]))
      : _createCommentVNode("", true),
    (_ctx.externalLinkUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.externalLinkUrl,
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          _createTextVNode(_toDisplayString(_ctx.linkText) + " ", 1),
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, { icon: "external-link" })
          ])
        ], 8, _hoisted_5))
      : _createCommentVNode("", true)
  ], 2))
}